import Api from './API'
import {getCookies} from '@/utils/cookies'
// import { get } from 'http'
// const crypto = require('crypto')

function AdminModel () {
  if (!(this instanceof AdminModel)) {
    return new AdminModel()
  }
}

/* ***** *********** ************* **************** ******************* ****************** ****
*
*                                             HRM material Api
*
***** *********** ************* **************** ******************* ****************** **** */
/** ***********************HRM SETTINGS START HERE*********************************/

/** ***********************CONTACT TYPE******************************** */

AdminModel.prototype.StaffSearch = async function (
  start,
  length,
  searchValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchValue", searchValue);
  Api.StaffSearch(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.CheckNumberExistStaff = async function (
  reqType,
  value,
  userDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append("reqType", reqType);
  data.append("value", value);
  data.append("userDetailId", userDetailId);
  Api.CheckNumberExistStaff(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetStaffList = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  isActive,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchValue", searchValue);
  data.append("sortColumnName", sortColumnName);
  data.append("sortColumnOrder", sortColumnOrder);
  data.append("isActive", isActive);
  Api.GetStaffList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetStaffDetail = async function (
  userDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append("userDetailId", userDetailId);
  Api.GetStaffDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.UpdateStaffApi = async function (
  requestType,
  requestObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('requestType',requestType)
  data.append('requestObj', JSON.stringify(requestObj))
  Api.UpdateStaffApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.UpdateAssigneeinDetail = async function (
  campaignMasterId,
  campaignAssignees,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('campaignMasterId',campaignMasterId)
  data.append('campaignAssignees', JSON.stringify(campaignAssignees))
  Api.UpdateAssigneeinDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.NewStaffSave = async function (
  reqObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('reqObj', JSON.stringify(reqObj))
  Api.NewStaffSave(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.saveNewListAPi = async function (
  contactListName,
  fileColumnsWithDBColumns,
  file,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('contactListName', contactListName)
  data.append('fileColumnsWithDBColumns', JSON.stringify(fileColumnsWithDBColumns))
  for (var i = 0; i < file.length; i++) {
    let fileA = file[i]
    data.append('file', fileA, fileA.name)
  }
  Api.saveNewListAPi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.CheckListNameExist = async function (
  value,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('value', value)
  Api.CheckListNameExist(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetListList = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchValue", searchValue);
  data.append("sortColumnName", sortColumnName);
  data.append("sortColumnOrder", sortColumnOrder);
  Api.GetListList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetSublistFromList = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  contactListMasterId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchValue", searchValue);
  data.append("sortColumnName", sortColumnName);
  data.append("sortColumnOrder", sortColumnOrder);
  data.append("contactListMasterId", contactListMasterId);
  Api.GetSublistFromList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.TwilioConfiguration = async function (
  reqObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('reqObj', JSON.stringify(reqObj))
  Api.TwilioConfiguration(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.TwilioConfigurationList = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  reqType,
  callBackFunc,
  onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("start", start);
    data.append("length", length);
    data.append("searchValue", searchValue);
    data.append("sortColumnName", sortColumnName);
    data.append("sortColumnOrder", sortColumnOrder);
    data.append("reqType", reqType);
    Api.TwilioConfigurationList(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.SaveNewCampaign = async function (
    reqObj,
    isCampaignStartNow,
    isSkipContacts,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append('reqObj', JSON.stringify(reqObj))
    data.append("isCampaignStartNow", isCampaignStartNow);
    data.append("isSkipContacts", isSkipContacts);
    Api.SaveNewCampaign(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.UpdateNewCampaign = async function (
    reqObj,
    isCampaignStartNow,
    isSkipContacts,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append('reqObj', JSON.stringify(reqObj))
    data.append("isCampaignStartNow", isCampaignStartNow);
    data.append("isSkipContacts", isSkipContacts);
    Api.UpdateNewCampaign(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.CampaignList = async function (
    start,
    length,
    searchValue,
    sortColumnName,
    sortColumnOrder,
    isActive,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("start", start);
    data.append("length", length);
    data.append("searchValue", searchValue);
    data.append("sortColumnName", sortColumnName);
    data.append("sortColumnOrder", sortColumnOrder);
    data.append("isActive", isActive);
    Api.CampaignList(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.DeleteCampaign = async function (
    campaignMasterId,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("campaignMasterId", campaignMasterId);
    Api.DeleteCampaign(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.UpdateLimit = async function (
    campaignMasterId,
    maxMessagePerMin,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("campaignMasterId", campaignMasterId);
    data.append("maxMessagePerMin", maxMessagePerMin);
    Api.UpdateLimit(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.SendLeadToCRMApi = async function (
    campaignMasterId,
    leadId,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("campaignMasterId", campaignMasterId);
    data.append("leadId", leadId);
    Api.SendLeadToCRMApi(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.GetTwilioConfDetail = async function (
    twilioConfigurationId,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("twilioConfigurationId", twilioConfigurationId);
    Api.GetTwilioConfDetail(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.MsgLimitUpdate = async function (
    reqObj,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append('reqObj', JSON.stringify(reqObj))
    Api.MsgLimitUpdate(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.GlobalSearch = async function (
    start,
    length,
    searchValue,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("start", start);
    data.append("length", length);
    data.append("searchValue", searchValue);
    Api.GlobalSearch(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.GetLeadListing = async function (
    start,
    length,
    searchValue,
    sortColumnName,
    sortColumnOrder,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("start", start);
    data.append("length", length);
    data.append("searchValue", searchValue);
    data.append("sortColumnName", sortColumnName);
    data.append("sortColumnOrder", sortColumnOrder);
    Api.GetLeadListing(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.GetLeadDetails = async function (
    campaignMasterId,
    leadId,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("campaignMasterId", campaignMasterId);
    data.append("leadId", leadId);
    Api.GetLeadDetails(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.GetLeadMessages = async function (
    start,
    length,
    campaignMasterId,
    leadId,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("start", start);
    data.append("length", length);
    data.append("campaignMasterId", campaignMasterId);
    data.append("leadId", leadId);
    Api.GetLeadMessages(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.SendSmsLeadScreen = async function (
    campaignMasterId,
    leadId,
    twilioConfigurationId,
    message,
    number,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("campaignMasterId", campaignMasterId);
    data.append("leadId", leadId);
    data.append("twilioConfigurationId", twilioConfigurationId);
    data.append("message", message);
    data.append("number", number);
    Api.SendSmsLeadScreen(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.StartCampaign = async function (
    campaignMasterId,
    maxMessagePerMin,
    isSkipContacts,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("campaignMasterId", campaignMasterId);
    data.append("maxMessagePerMin", maxMessagePerMin);
    data.append("isSkipContacts", isSkipContacts);
    Api.StartCampaign(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.StopCampaign = async function (
    campaignMasterId,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("campaignMasterId", campaignMasterId);
    Api.StopCampaign(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.CheckStartTimeAlert = async function (
    campaignMasterId,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("campaignMasterId", campaignMasterId);
    Api.CheckStartTimeAlert(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  // GET
  
  
AdminModel.prototype.GetMessageLimit = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('authToken')
  Api.GetMessageLimit(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetEmailConfDetail = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('authToken')
  Api.GetEmailConfDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getListDatabase = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('authToken')
  Api.getListDatabase(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetTimeZones = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('authToken')
  Api.GetTimeZones(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetCampaignDetail = async function (
  campaignMasterId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('authToken') + '&campaignMasterId=' + campaignMasterId
  Api.GetCampaignDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.StartCampaignAlert = async function (
  campaignMasterId,
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('authToken') + '&campaignMasterId=' + campaignMasterId
  Api.StartCampaignAlert(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetPrefrenceTag = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('authToken')
  Api.GetPrefrenceTag(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.PlivoConfigurationList = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  reqType,
  callBackFunc,
  onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("start", start);
    data.append("length", length);
    data.append("searchValue", searchValue);
    data.append("sortColumnName", sortColumnName);
    data.append("sortColumnOrder", sortColumnOrder);
    data.append("reqType", reqType);
    Api.PlivoConfigurationList(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
AdminModel.prototype.PlivoConfigurationDetail = async function (
  plivoConfigurationId,
  callBackFunc,
  onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("plivoConfigurationId", plivoConfigurationId);
    Api.PlivoConfigurationDetail(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }

AdminModel.prototype.PlivoConfigurationSave = async function (
  reqObj,
  callBackFunc,
  onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append("reqObj", JSON.stringify(reqObj));
    Api.PlivoConfigurationSave(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
AdminModel.prototype.ActiveCommunicationNumbers = async function (
  callBackFunc,
  onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    Api.ActiveCommunicationNumbers(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
AdminModel.prototype.SendLeadSMSViaPlivo = async function (
  campaignMasterId,
  leadId,
  plivoConfigurationId,
  message,
  number,
  callBackFunc,
  onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append('campaignMasterId', campaignMasterId)
    data.append('leadId', leadId)
    data.append('plivoConfigurationId', plivoConfigurationId)
    data.append('message', message)
    data.append('number', number)
    Api.SendLeadSMSViaPlivo(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.SmsTemplateAddEdit = async function (
    reqObj,
    callBackFunc,
    onError
  ) {
    const data = new FormData()
    data.append('authToken', getCookies('authToken'))
    data.append('reqObj', JSON.stringify(reqObj))
    Api.SmsTemplateAddEdit(data).then(data => {
      if (data.success === 1) {
        callBackFunc(data)
      } else {
        onError(data)
      }
    })
  }
  AdminModel.prototype.SmsTemplateDetail = async function (
    templateId,
    callBackFunc,
    onError
    ) {
      const data = new FormData()
      data.append('authToken', getCookies('authToken'))
      data.append('templateId', templateId)
      Api.SmsTemplateDetail(data).then(data => {
        if (data.success === 1) {
          callBackFunc(data)
        } else {
          onError(data)
        }
      })
    }
  AdminModel.prototype.SmsTemplateList = async function (
    callBackFunc,
    onError
    ) {
      const data = new FormData()
      data.append('authToken', getCookies('authToken'))
      Api.SmsTemplateList(data).then(data => {
        if (data.success === 1) {
          callBackFunc(data)
        } else {
          onError(data)
        }
      })
    }
  AdminModel.prototype.smsTemplateReOrder = async function (
    smsTemplateList,
    callBackFunc,
    onError
    ) {
      const data = new FormData()
      data.append('authToken', getCookies('authToken'))
      data.append('smsTemplateList', JSON.stringify(smsTemplateList))
      Api.smsTemplateReOrder(data).then(data => {
        if (data.success === 1) {
          callBackFunc(data)
        } else {
          onError(data)
        }
      })
    }
    AdminModel.prototype.SmsTemplateDelete = async function (
      templateId,
      callBackFunc,
      onError
      ) {
        const data = new FormData()
        data.append('authToken', getCookies('authToken'))
        data.append('templateId', templateId)
        Api.SmsTemplateDelete(data).then(data => {
          if (data.success === 1) {
            callBackFunc(data)
          } else {
            onError(data)
          }
        })
      }
    AdminModel.prototype.SmsTemplateActiveList = async function (
      callBackFunc,
      onError
      ) {
        const data = new FormData()
        data.append('authToken', getCookies('authToken'))
        Api.SmsTemplateActiveList(data).then(data => {
          if (data.success === 1) {
            callBackFunc(data)
          } else {
            onError(data)
          }
        })
      }
    AdminModel.prototype.MergeSmsTemplate = async function (
      templateId,
      campaignMasterId,
      leadId,
      callBackFunc,
      onError
      ) {
        const data = new FormData()
        data.append('authToken', getCookies('authToken'))
        data.append('templateId', templateId)
        data.append("campaignMasterId", campaignMasterId);
        data.append("leadId", leadId);
        Api.MergeSmsTemplate(data).then(data => {
          if (data.success === 1) {
            callBackFunc(data)
          } else {
            onError(data)
          }
        })
      }
export default AdminModel
